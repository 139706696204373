<template>
  <div class="ip-show">
    <div class="box">
      <div class="introduce">
        <span class="title">
          {{ languageSelect.my }}
        </span>
        <span class="ip-country"><i class="flag-icon ip-country-flag" :class="flagIpShow"></i>
          {{ countryName }}
        </span>
      </div>
      <div class="ip">
        <h1>{{ ipInfoServer.clientIp }}</h1>
      </div>
      <div class="safe-container">
        <a href="https://www.mbbrowser.com/" target="_blank" class="safe">
          {{ languageSelect.safe }}
          <div class="switch-box">
            <div class="switch-button"></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
export default {
  name: "TheIpShow",
  setup () {
    const ipInfoServer = inject('ipInfoServer')
    const languageSelect = inject('languageSelect')

    return {
      ipInfoServer, languageSelect
    }
  },
  computed: {
    flagIpShow () {
      return 'flag-icon-' + this.ipInfoServer.countryCode.toLowerCase()
    },
    countryName () {
      if (this.ipInfoServer.countryCode !== 'N/A') {
        if (this.ipInfoServer.countryCode.toLowerCase() in this.languageSelect.country) {
          return this.languageSelect.country[this.ipInfoServer.countryCode.toLowerCase()].name
        } else {
          return 'unknownCountry'
        }
      } else {
        return 'N/A'
      }
    }
  },
  methods: {
    refreshTime() {
    }
  }
}
</script>

<style scoped>
.ip-info{
  color: #FFFFFF;
}
.ip-show{
  padding: 60px 20px;
  display: flex;
  justify-content: center;
}
.ip{
  padding-bottom: 10px;
  border-bottom: 2px #4A5E70 solid;
  text-align: center;
}
.ip h1{
  font-size: 48px;
  color: #FFFFFF;
  font-weight: 600;
}

.introduce{
  display: flex;
  padding-bottom: 4px;
  font-weight: bold;
}

.title{
  font-size: 24px;
  color: #FFFFFF;
  flex: 1;
}

.ip-country{
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #FFFFFF;
}

.ip-country-flag{
  border-radius: 4px;
  overflow: hidden;
  display: block;
  padding-right: 6px;
}

.safe-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.safe{
  display: flex;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: bold;
  padding-top: 10px;
}

.switch-box{
  height: 30px;
  width: 60px;
  background: #E6E6E6;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.switch-button{
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background: #FFFFFF;
  margin-left: 4px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
@media only screen and ( max-width: 468px ){
  .title{
    font-size: 18px;
  }
  .ip h1{
    font-size: 36px;
  }
  .safe{
    font-size: 16px;
  }
}
</style>
